import React, { useEffect, useState } from "react";
import * as C from "./styles";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../components/Button";
import { StateGlobal } from "../../../context/GlobalContext";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import { CpfMask, PhoneMask } from "../../../components/Masks";
import { getDataUser } from '../../../services/auth'

export default function RegisterStepThree() {
  const navigate = useNavigate();
  const { data_user, setData_user } = StateGlobal();
  const [aux_email, setAux_email] = useState(data_user?.email);
  const res = getDataUser();

  function handleNext() {
    navigate("/register/4");
  }


  function getDataStep() {
    setData_user({
      birthday: res?.birthday,
      cpf: res?.cpf,
      email: res?.email,
      id: res?.id,
      name: res?.name,
      phone: res?.phone,
    });
  }

  useEffect(() => {
    getDataStep();
  }, []);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
          <C.Subtitle>
            Passo <b>1</b> de <b>2</b>
          </C.Subtitle>
        </C.Image>
        <C.Form>
          <C.Text>Por favor confirme os dados pessoais:</C.Text>
          <C.InputAndLabel>
            <label id="name">Nome</label>
            <TextField
              size="small"
              placeholder="Ana Carolina"
              value={data_user?.name}
              onChange={(e) =>
                setData_user({ ...data_user, name: e.target.value })
              }
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              size="small"
              placeholder="232.212.321-21"
              disabled
              value={CpfMask(data_user?.cpf)}
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <label id="birthday">Data de Nascimento</label>
            <TextField
              size="small"
              type="date"
              value={data_user?.birthday}
              onChange={(e) =>
                setData_user({ ...data_user, birthday: e.target.value })
              }
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <label id="email">Email</label>
            <TextField
              size="small"
              placeholder="ana_carol2123@gmail.com"
              value={data_user?.email}
              onChange={(e) =>
                setData_user({ ...data_user, email: e.target.value })
              }
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <label id="phone">Telefone</label>
            <TextField
              size="small"
              placeholder="(15) 9 98212-2123"
              value={PhoneMask(data_user?.phone)}
              onChange={(e) =>
                setData_user({ ...data_user, phone: e.target.value })
              }
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton text="Continuar" funcao={handleNext} />
          </C.ContainerButton>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
