import * as C from "./styles";
import React from "react";
import PrimaryButton from "../../components/Button";
import background_logo from "../../assets/images/logo-centro-medico.png";

import OutlinedButton from "../../components/OutlinedButton";

export default function InitialScreen() {
  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Text>
            Para acessar um atendimento específico através do seu login e senha gerados automaticamente em seu exame clique abaixo
          </C.Text>
          <OutlinedButton text="Entrar com login e senha do atendimento" route='/login/private' />
          <C.Text style={{ marginTop: 50 }}>
            Para acessar seu histórico com todos os seus atendimentos e exames, faça o seu acesso a sua conta abaixo
          </C.Text>
          <PrimaryButton
            text="Acessar minha conta"
            route='/login'
          />
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
