import * as C from "./styles";
import api from "../../../services/api.js";
import { useNavigate, useLocation } from "react-router-dom";

import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/Button";
import { StateGlobal } from "../../../context/GlobalContext";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function StepVerification() {
  const MySwal = withReactContent(Swal);
  const location = useLocation();
  const { data_user, setData_user } = StateGlobal();
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [type, setType] = useState("");

  function handleSelect(type) {
    if (type === "email") {
      setType("email");
    } else {
      setType("phone");
    }
  }

  function handleback() {
    navigate("/register/1");
  }

  async function sendToken() {
    setDisabled(true);
    try {
      const res = await api.post("/api/client/token/send", {
        cpf: data_user?.cpf,
        type,
        content: type === "email" ? data_user?.email : data_user.phone,
      });
      if (res.data.error === "true") {
        MySwal.fire({
          icon: "error",
          title: `${res.data.message}`,
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        navigate("/register/2", { state: { type: type } });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDisabled(false);
    }
  }

  useEffect(() => {
    if (type === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [type]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Text>
            Por favor selecione uma das opções abaixo para receber seu token de
            acesso e continuar com o seu cadastro:
          </C.Text>
          <C.ContainerForm>
            <C.ContainerCheckbox>
              <C.RoundedInput
                checked={type === "email"}
                onClick={() => handleSelect("email")}
              />{" "}
              <C.Text >
                Email: <span>{location?.state?.email_label}</span>
              </C.Text>
            </C.ContainerCheckbox>
            <C.ContainerCheckbox>
              <C.RoundedInput
                checked={type === "phone"}
                onClick={() => handleSelect("phone")}
              />{" "}
              <C.Text >
                Telefone:
                <span>{location?.state?.phone_label}</span>
              </C.Text>
            </C.ContainerCheckbox>
          </C.ContainerForm>
          <C.ContainerButton>
            <PrimaryButton
              text="Continuar"
              funcao={sendToken}
              disabled={disabled}
            />
          </C.ContainerButton>
          <C.ContainerTextBack>
            <C.TextUnderline onClick={handleback}>Voltar</C.TextUnderline>
          </C.ContainerTextBack>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
