import React, { useState, useEffect } from "react";
import * as C from "./styles";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { TextField, CircularProgress } from "@mui/material";
import SecondButton from "../Button/index";
import { X } from "react-feather";
import api from "../../services/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxWidth: "700px",
  bgcolor: "#FFF",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  padding: "30px 30px",
};

export default function ModalPassword(props) {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [password, setPassword] = useState("");
  const [new_password, setNew_password] = useState("");
  const [aux_password, setAux_password] = useState("");

  function validateButton() {
    if (
      password?.length >= 8 &&
      new_password?.length >= 8 &&
      aux_password.length >= 8 &&
      new_password === aux_password
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  async function handleUpdateInfo() {
    try {
      setIsLoading(true);
      const res = await api.put("/api/client/update", {
        password,
        new_password,
      });
      if (res.data.error === "false") {
        props.funcao();
      }
      setIsLoading(false);
      return (
        <div style={{ zIndex: '999' }}>
          {MySwal.fire({
            icon: res.data.error === "false" ? "success" : "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000,
          })};
        </div>
      )
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    validateButton();
  }, [new_password, aux_password, password]);

  return (
    <C.Container>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.funcao}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ zIndex: 9 }}
      >
        <Box sx={style}>
          {isLoading ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              <C.ContainerIcon>
                <X color="#0F2554" onClick={props.funcao} />
              </C.ContainerIcon>
              <Typography
                style={{
                  color: "#0F2554",
                  fontSize: "20px",
                  fontWeight: 600,
                  fontFamily: "Montserrat, sans-serif",
                  margin: "0px 0px",
                }}
              >
                Alterar senha de acesso
              </Typography>
              <C.ContentForm>
                <C.InputAndLabel>
                  <label>Senha atual</label>
                  <TextField
                    fullWidth={true}
                    size="small"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </C.InputAndLabel>

                <C.InputAndLabel>
                  <label>Nova senha</label>
                  <TextField
                    fullWidth={true}
                    size="small"
                    type="password"
                    value={new_password}
                    onChange={(e) => setNew_password(e.target.value)}
                  />
                </C.InputAndLabel>

                <C.InputAndLabel>
                  <label>Confirmar nova senha</label>
                  <TextField
                    fullWidth={true}
                    size="small"
                    type="password"
                    value={aux_password}
                    onChange={(e) => setAux_password(e.target.value)}
                  />
                </C.InputAndLabel>
              </C.ContentForm>
              <C.ContainerButton>
                <SecondButton
                  text="Atualizar senha"
                  funcao={handleUpdateInfo}
                  disabled={isDisabled}
                />
              </C.ContainerButton>
            </>
          )}
        </Box>
      </Modal>
    </C.Container>
  );
}
