import * as C from "./styles";
import api from "../../services/api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/Button";
import { StateGlobal } from "../../context/GlobalContext";
import { saveToken, setIsDoctor } from "../../services/auth";
import background_logo from "../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { CpfMask } from "../../components/Masks";

export default function Login() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [cpf, setCPF] = useState("");
  const { setComponents } = StateGlobal();
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  function handleRegister() {
    navigate("/register/1");
  }

  async function handleSubmit() {
    setDisabled(true);
    try {
      const res = await api.post("/api/client/login", {
        cpf: cpf?.replace(/[^0-9s]/g, ""),
        password,
      });
      if (res.data.error === "false") {
        setIsDoctor(false);
        saveToken(res.data.token);
        setTimeout(() => {
          setComponents(1);
          window.location.href = `/home`;
        }, [1000]);
      }
      if (res.data.error === "true") {
        MySwal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setDisabled(false);
    } catch (err) {
      if (err.response.status === 422) {
        MySwal.fire({
          icon: "error",
          title: "CPF inválido",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Erro! tente novamente",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setDisabled(false);
    }
  }

  function validateMail() {
    if (cpf !== "" && password !== "" && password.length > 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateMail();
  }, [cpf, password]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Bem vindo! 👋🏻</C.Title>
          <C.Text>
            Por favor preencha seu CPF e senha de acesso a sua conta.
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              size="small"
              placeholder="000.000.000-00"
              value={CpfMask(cpf) ?? cpf}
              onChange={(e) => setCPF(e.target.value)}
              inputProps={{ maxLength: 14 }}
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <div>
              <label>Senha</label>
              <C.LabelPassword
                onClick={() => {
                  navigate("/forgotpassword");
                }}
              >
                Esqueceu a senha?
              </C.LabelPassword>
            </div>
            <TextField
              size="small"
              type="password"
              placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton
              text="Acessar"
              funcao={handleSubmit}
              disabled={disabled}
            />
          </C.ContainerButton>
          <C.Text>
            Não tem cadastro? <b onClick={handleRegister}>Cadastre-se</b>
          </C.Text>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
