import styled from "styled-components";
import background_login from "../../../../assets/images/background-login.jfif";

export const Container = styled.div`
  height: 100vh;
  background-image: url(${background_login});
  background-size: cover;
`;

export const Header = styled.div`
  height: 265px;
`;

export const Content = styled.div`
  width: 420px;
  min-height: 320px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 11%;
  margin-top: 10px;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  img {
    width: 221px;
    height: auto;
  }
`;

export const Form = styled.div`
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  width: 85%;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: justify;
  color: ${({ theme }) => theme.colors.text_login};
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  margin-top: 10%;
  margin-bottom: 5%;
`;

export const TextInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
  }
`;

export const ContainerOTPInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UnderlineText = styled.p`
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.4px;
  margin-top: 5%;
  margin-bottom: 0px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const TextDisabled = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.4px;
  margin-top: 5%;
  margin-bottom: 0px;
  color: ${({ theme }) => theme.colors.grey};

  b {
    font-size: 14px;
    font-weight: 900;
    line-height: 14px;
    letter-spacing: 0.4px;
    margin-top: 5%;
    margin-bottom: 0px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const TextBack = styled.p`
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  letter-spacing: 0.4px;
`;
