import * as C from "./styles";
import { useState, useEffect } from "react";
import api from "../../services/api";
import { Card } from "@mui/material";
import { TextField, CircularProgress } from "@mui/material";
import { Unlock } from "react-feather";
import Button from "../../components/Button";
import { StateGlobal } from "../../context/GlobalContext";
import ModalPassword from "../../components/ModalPassword";
import profile from "../../assets/images/profile-image.png";
import logo from "../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  PhoneMask
} from "../../components/Masks";
export default function Profile() {
  const MySwal = withReactContent(Swal);
  const { setComponents, clientData, setClientData } = StateGlobal();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    id: "",
    cm_id: "",
    name: "",
    cpf: "",
    birthday: "",
    email: "",
    phone: "",
  });
  const [loadFoto, setLoadFoto] = useState(false);

  // function getBase64(file) {
  //   if (file.size > 10000000) {
  //     alert(
  //       "Documento maior que 10MB, por favor verifique o tamanho do arquivo."
  //     );
  //     return;
  //   }

  //   var reader = new FileReader();

  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     putFotoPerfil(reader.result);
  //   };
  // }

  // async function putFotoPerfil(image) {
  //   setLoadFoto(true);
  //   try {
  //     const response = await api.post("/api/client/picture", { photo: image });
  //     if (response.data) {
  //       setClientData({
  //         photo: response.data.photo,
  //       });
  //       MySwal.fire({
  //         icon: response.data.error === "false" ? "success" : "error",
  //         title: response.data.message,
  //         showConfirmButton: false,
  //         timer: 3000,
  //       });
  //       localStorage.setItem("attPhoto", true);
  //       setTimeout(() => {
  //         document.location.reload(true);
  //       }, [2000]);
  //     }
  //   } catch (err) { }
  // }

  function SwitchModal() {
    setOpen((prevState) => (prevState ? false : true));
  }

  async function getProfileInfo() {
    try {
      setIsLoading(true);
      const res = await api.get("/api/client");
      setUser({
        birthday: res.data.birthday,
        cm_id: res.data.cm_id,
        cpf: res.data.cpf,
        email: res.data.email,
        id: res.data.id,
        name: res.data.name,
        phone: res.data.phone,
      });

      setClientData({
        photo: res.data.photo,
      });

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  async function handleUpdateInfo() {
    try {
      setIsLoading(true);
      const res = await api.put("/api/client/update", {
        name: user.name,
        cpf: user.cpf,
        email: user.email,
        birthday: user.birthday.split("/").reverse().join("-"),
        phone: user.phone,
      });
      MySwal.fire({
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 3000,
      });
      localStorage.setItem("attPhoto", true);
      setTimeout(() => {
        document.location.reload(true);
      }, [2000]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      MySwal.fire({
        icon: "error",
        title: 'Erro ao atualizar informações.',
        showConfirmButton: false,
        timer: 3000,
      });

    }
  }

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <C.Container>
      <C.Logo>
        <img alt="logo" src={logo} width={160} height={50} />
      </C.Logo>
      <C.Title>Meu perfil</C.Title>
      <C.TextHeader>Meu ID:</C.TextHeader>

      <C.TextBoldHeader>{isLoading ? "..." : user.cm_id}</C.TextBoldHeader>
      <C.Content>
        <C.TitleContent>Dados Pessoais</C.TitleContent>
        <C.Underline />
        <Card style={{ color: "#FFF", padding: "20px 40px" }}>
          {isLoading ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              {/* <C.Avatar>
                <img
                  alt="profile"
                  src={clientData.photo !== null ? clientData.photo : profile}
                  height="auto"
                />
              </C.Avatar> */}
              {/* <label htmlFor="addFoto">
                <C.ItemIconEdit>
                  {loadFoto ? (
                    <div style={{ paddingTop: "2px" }}>
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    </div>
                  ) : (
                    <img alt="iconEdit" src={IconEdit} />
                  )}

                  <input
                    id="addFoto"
                    type="file"
                    hidden
                    onChange={(event) => getBase64(event.target.files[0])}
                  />
                </C.ItemIconEdit>
              </label> */}

              <C.InputAndLabel>
                <label>Nome:</label>
                <TextField
                  size="small"
                  value={user.name}
                  onChange={(e) => setUser({ ...user, name: e.target.value })}
                />
              </C.InputAndLabel>
              <C.DivRow>
                <C.MediumInput>
                  <C.InputAndLabel>
                    <label>Telefone:</label>
                    <TextField
                      size="small"
                      value={PhoneMask(user.phone)}
                      onChange={(e) =>
                        setUser({ ...user, phone: e.target.value })
                      }
                    />
                  </C.InputAndLabel>
                </C.MediumInput>
                <C.MediumInput>
                  <C.InputAndLabel>
                    <label>Email:</label>
                    <TextField
                      size="small"
                      value={user.email}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </C.InputAndLabel>
                </C.MediumInput>
              </C.DivRow>
              <C.Export onClick={SwitchModal}>
                <Unlock
                  color="#041e42"
                  style={{ marginLeft: "10px", width: "10%" }}
                />
                <span>alterar senha de acesso</span>
              </C.Export>
              <C.ContainerButtons>
                <C.Buttons>
                  <Button text="Salvar Informações" funcao={handleUpdateInfo} />
                </C.Buttons>
                <C.Buttons style={{ marginLeft: "20px" }}>
                  {/* <OutlinedButton text="Cancelar" /> */}
                </C.Buttons>
              </C.ContainerButtons>
              {open === true ? (
                <ModalPassword open={open} funcao={SwitchModal} />
              ) : (
                ""
              )}
            </>
          )}
        </Card>
      </C.Content>
    </C.Container>
  );
}
