import * as C from "./styles";
import Results from "../Results";
import Profile from "../Profile";
import Schedules from "../Schedules";
import Procediments from "../Procediments";
import LeftMenu from "../../components/LeftMenu";
import { StateGlobal } from "../../context/GlobalContext";
import { useEffect } from "react";

export default function Home() {
  const { components, setComponents } = StateGlobal();
  let attPhoto = localStorage.getItem("attPhoto");
  function validateComponents() {
    if (attPhoto) {
      localStorage.removeItem("attPhoto");
      setComponents(5);
    }
  }

  useEffect(() => {
    validateComponents();
  }, []);

  return (
    <C.Container>
      <LeftMenu />
      {components === 1 ? (
        <Results />
      ) : components === 2 ? (
        <Schedules />
      ) : components === 3 ? (
        <Procediments />
      ) : components === 5 ? (
        <Profile />
      ) : (
        ""
      )}
    </C.Container>
  );
}
