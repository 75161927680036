import * as C from "./styles";
import api from "../../../services/api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../components/Button";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function LoginInsurance() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  async function handleSubmit() {
    setDisabled(true);
    try {
      const { data } = await api.post("/api/insurance/token/send", {
        email,
        password,
      });
      if (data.error === "false") {
        navigate("/login/convenios/codigo", {
          state: { email: email, password: password },
        });
      } else {
        MySwal.fire({
          icon: data.error === "false" ? "success" : "error",
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Erro! tente novamente",
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setDisabled(false);
    }
  }

  function validateMail() {
    if (email !== "" && password !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateMail();
  }, [email, password]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Bem vindo! 👋🏻</C.Title>
          <C.Text>
            Por favor preencha seu email e senha de acesso a sua conta.
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">Email</label>
            <TextField
              size="small"
              placeholder="exemplo@email.com.br"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <div>
              <label>Senha</label>
            </div>
            <TextField
              size="small"
              type="password"
              placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton
              text="Acessar"
              funcao={handleSubmit}
              disabled={disabled}
            />
          </C.ContainerButton>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
