import * as C from "./styles";
import api from "../../../services/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../../services/auth";
import { StateGlobal } from "../../../context/GlobalContext";
import logoCentroMedico from "../../../assets/images/logoCentroMedico.png";
import { Mail, Clipboard, ArrowLeft } from "react-feather";

export default function LeftMenuPrivate() {
  const { setComponents } = StateGlobal();
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState({});
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      icon: <Clipboard color="#FFF" width={35} height={35} strokeWidth={1} />,
      name: "Meus Resultados",
    },

    {
      id: 3,
      icon: <Clipboard color="#FFF" width={35} height={35} strokeWidth={1} />,
      name: "Preparos de Exames",
    },
    {
      id: 4,
      icon: <Mail color="#FFF" width={35} height={35} strokeWidth={1} />,
      name: "Fale Conosco",
    },
    {
      id: 6,
      icon: <ArrowLeft color="#FFF" width={35} height={35} strokeWidth={1} />,
      name: "Sair",
    },
  ];

  function handleResults() {
    setComponents(1);
  }

  function handleProcediments() {
    setComponents(3);
  }

  function handleTalkUs() {
    window.open(`https://centromedicosorocaba.com.br/contato/`, "_blank");
  }

  async function handleLogout() {
    const res = await api.post("/api/insurance/logout");
    if (res.data.error === "false") {
      removeToken();
      navigate("/login/convenios");
    }
  }

  async function getInformationInsurance() {
    setLoading(true);
    try {
      const { data } = await api.get("/api/insurance");
      setInsurance(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getInformationInsurance();
  }, []);

  return (
    <C.LeftMenu>
      <>
        <C.Header>
          <img alt="profile" src={logoCentroMedico} />
          <>
            <C.TitleHeader>
              {loading ? "Carregando..." : insurance?.name}
            </C.TitleHeader>
            <C.SubtitleHeader>{" Meu ID"}</C.SubtitleHeader>
            <C.Text>{loading ? "Carregando..." : insurance?.cm_id}</C.Text>
          </>
        </C.Header>
        <C.Content>
          <C.MenuOptions>
            {data?.map((item, index) => (
              <C.DivMap
                key={String(index)}
                onClick={() => {
                  item.name === "Meus Resultados" && handleResults();
                  item.name === "Preparos de Exames" && handleProcediments();
                  item.name === "Fale Conosco" && handleTalkUs();
                  item.name === "Sair" && handleLogout();
                }}
              >
                {item.icon}
                <span> {item.name} </span>
              </C.DivMap>
            ))}
          </C.MenuOptions>
        </C.Content>
      </>
    </C.LeftMenu>
  );
}
