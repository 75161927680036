import * as C from "./styles";
import { StateGlobal } from "../../context/GlobalContext";
import { useEffect } from "react";
import LeftMenuPrivate from "../../components/LeftMenuPrivate";
import PrivateResults from "../PrivateResults";
import PrivateProcediments from "../PrivateProcediments";

export default function HomePrivate() {
  const { components, setComponents } = StateGlobal();
  let attPhoto = localStorage.getItem("attPhoto");
  function validateComponents() {
    if (attPhoto) {
      localStorage.removeItem("attPhoto");
      setComponents(5);
    }
  }

  useEffect(() => {
    validateComponents();
  }, []);

  return (
    <C.Container>
      <LeftMenuPrivate />
      {components === 1 ? (
        <PrivateResults />
      ) : components === 3 ? (
        <PrivateProcediments />
      ) : (
        ""
      )}
    </C.Container>
  );
}
