export const saveUser = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
};

export const getDataUser = () => {
  return JSON.parse(localStorage.getItem('userData'))
}

export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const removeToken = () => {
  return localStorage.removeItem("token");
};

export const setIsDoctor = (data) => {
  sessionStorage.setItem("doctor", data);
};

export const getIsDoctor = () => {
  return sessionStorage.getItem("doctor");
};
