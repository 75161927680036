import * as C from "./styles";
import api from "../../services/api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/Button";
import { StateGlobal } from "../../context/GlobalContext";
import { saveToken, setIsDoctor } from "../../services/auth";
import background_logo from "../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function PrivateLogin() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [login, setLogin] = useState("");
  const { setComponents } = StateGlobal();
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  function handleRegister() {
    navigate("/login");
  }

  async function handleSubmit() {
    setDisabled(true);
    try {
      const res = await api.post("/api/anonymous/login", {
        login,
        password,
      });
      if (res.data.error === "false") {
        setIsDoctor(false);
        saveToken(res.data.token);
        setTimeout(() => {
          setComponents(1);
          window.location.href = `/private`;
        }, [1000]);
      } else {
        MySwal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }

      setDisabled(false);
    } catch (err) {
      if (err.response.status === 422) {
        MySwal.fire({
          icon: "error",
          title: "E-mail inválido",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Erro! tente novamente",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setDisabled(false);
    }
  }

  function validateMail() {
    if (login !== "" && password !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateMail();
  }, [login, password]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Acesso restrito</C.Title>
          <C.Text>
            Por favor digite abaixo o login e senhas gerados automaticamente em
            seu exame
          </C.Text>
          <C.InputAndLabel>
            <label id="email">Login</label>
            <TextField
              size="small"
              placeholder="000000"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <div>
              <label>Senha</label>
            </div>
            <TextField
              size="small"
              type="password"
              placeholder="digite a senha automática"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </C.InputAndLabel>
          <C.ContainerButton>
            <PrimaryButton
              text="Continuar"
              funcao={handleSubmit}
              disabled={disabled}
            />
          </C.ContainerButton>
          {/* <C.Text>
            Já tem cadastro? <b onClick={handleRegister}>Faça login agora</b>
          </C.Text> */}
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
