import React, { useEffect, useState } from "react";
import * as C from "./styles";
import api from "../../../services/api";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../../components/Button";
import { StateGlobal } from "../../../context/GlobalContext";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function RegisterStepFour() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const { data_user, setData_user } = StateGlobal();
  const [alert, setAlert] = useState(false);
  const [aux_password, setAux_password] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  function validatePassword() {
    if (data_user?.password?.length > 0 && data_user.password?.length < 8) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }

  function validateButton() {
    if (data_user?.password === aux_password && alert === false) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  function handleBack() {
    navigate("/register/3");
  }

  async function handleSubmit() {
    setIsDisabled(true);
    try {
      const res = await api.put("/api/client/update", {
        name: data_user?.name,
        cpf: data_user?.cpf,
        email: data_user?.email,
        birthday: data_user?.birthday?.split("/").reverse()?.join("-"),
        phone: data_user?.phone,
        password: data_user?.password,
      });
      MySwal.fire({
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 3000,
      });
      if (res.data.error === "false") {
        setData_user([])
        navigate("/login");
      }
      setIsDisabled(false);
    } catch (err) {
      setIsDisabled(false);
      console.log(err);
    }
  }

  useEffect(() => {
    validatePassword();
    validateButton();
  }, [data_user?.password, aux_password, alert]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
          <C.Subtitle>
            Passo <b>2</b> de <b>2</b>
          </C.Subtitle>
        </C.Image>
        <C.Form>
          <C.Text>Crie uma senha de acesso:</C.Text>
          <C.InputAndLabel>
            <label id="password">Nova senha</label>
            <TextField
              size="small"
              type="password"
              value={data_user?.password}
              onChange={(e) =>
                setData_user({ ...data_user, password: e.target.value })
              }
            />
          </C.InputAndLabel>
          <C.InputAndLabel>
            <label id="confirm-password">Confirmar senha</label>
            <TextField
              size="small"
              type="password"
              value={aux_password}
              onChange={(e) => setAux_password(e.target.value)}
            />
          </C.InputAndLabel>
          {alert === true ? (
            <C.Alert>Sua senha precisa ter no mínimo 8 caracteres</C.Alert>
          ) : (
            ""
          )}
          <C.ContainerButton>
            <PrimaryButton
              text="Concluir Cadastro"
              funcao={handleSubmit}
              disabled={isDisabled}
            />
            <C.TextBack onClick={handleBack}>Voltar</C.TextBack>
          </C.ContainerButton>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
