import * as C from "./styles";
import { useState, useEffect } from "react";
import api from "../../../services/api";
import Button from "../../../components/Button";
import { Printer, ArrowUpRight } from "react-feather";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { TextField, CircularProgress, MenuItem } from "@mui/material";
import logo from "../../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function ResultsInsurance() {
  const [bodyFilter, setBodyFilter] = useState({
    start: "",
    end: "",
    type: "name",
    content: "",
  });

  const typesFilter = [
    {
      value: "name",
      label: "Nome",
    },
    {
      value: "cpf",
      label: "CPF",
    },
  ];

  const MySwal = withReactContent(Swal);
  const [progressPdf, setProgressPdf] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingLink, setLoadingLink] = useState(false);
  const [results, setResults] = useState([]);
  const [IdRow, setIdRow] = useState("");
  const [progressPdfUnit, setprogressPdfUnit] = useState(false);
  const [progressPdfAll, setprogressPdfAll] = useState(false);

  function verifyDisabled() {
    if (bodyFilter?.start !== "" && bodyFilter.end !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  useEffect(() => {
    verifyDisabled();
  }, [bodyFilter]);

  async function GetResults() {
    try {
      setIsLoading(true);
      const { data } = await api.post(
        `/api/insurance/list/results`,
        bodyFilter
      );
      setResults(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function redirect(row, index) {
    setIdRow(index);
    try {
      setLoadingLink(true);
      const { data } = await api.post("/api/insurance/list/results/image/url", {
        client_id: row?.client_id,
        accnumber: row?.accnumber,
      });
      if (data?.result_url !== null) {
        window.open(`${data?.result_url}`, "_blank");
      } else {
        MySwal.fire({
          icon: "error",
          title: `Exame ainda não disponível. <p>Data de previsão: ${data.data_previsao} </p>`,
          showConfirmButton: false,
          timer: 5000,
        } );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingLink(false);
    }
  }

  async function redirectByServiceId(row, index) {
    setIdRow(index);
    setprogressPdfUnit(true);

    const { data } = await api.post(
      `/api/insurance/list/results/single/print`,
      {
        complemento_atendimento: row?.complemento_atendimento,
      }
    );

    const linkSource = `data:application/pdf;base64,${data.pdf_data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${row.name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setprogressPdfUnit(false);
  }

  async function printAll(result, index) {
    setIdRow(index);
    setprogressPdfAll(true);
    try {
      const { data } = await api.post("/api/insurance/list/results/print", {
        date: result?.date_block,
        atendimento: result?.atendimento,
        client_id: result?.registers[0]?.client_id,
      });
      if (data.pdf_data) {
        const linkSource = `data:application/pdf;base64,${data.pdf_data}`;
        const downloadLink = document.createElement("a");
        const fileName = `Atendimentos.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        setprogressPdfAll(false);
      } else {
        MySwal.fire({
          icon: "error",
          title: data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        setprogressPdfAll(false);
      }
    } catch (err) {
      console.log(err);
      setProgressPdf(false);
    }
  }

  console.log(`bodyFilter`, bodyFilter);

  return (
    <C.Container>
      <C.Logo>
        <img
          alt="logo"
          src={logo}
          width={160}
          height={50}
        />
      </C.Logo>

      <C.Title>Resultados de exame</C.Title>

      <C.Header>
        <C.Filters>
          <C.SmallInput>
            <C.InputAndLabel>
              <label>Data inicial:</label>
              <TextField
                mask="yyyy/mm/dd"
                type="date"
                size="small"
                value={bodyFilter?.start}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, start: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.SmallInput>
          <C.SmallInput>
            <C.InputAndLabel>
              <label>Data final:</label>
              <TextField
                type="date"
                size="small"
                value={bodyFilter?.end}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, end: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.SmallInput>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Tipo do filtro:</label>
              {/* <TextField
                size="small"
                value={bodyFilter?.content}
                inputProps={{ maxLength: 11 }}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, content: e.target.value })
                }
              /> */}

              <TextField
                size="small"
                select
                defaultValue="name"
                value={bodyFilter.type}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, type: e.target.value })
                }
              >
                {typesFilter.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </C.InputAndLabel>
          </C.MediumInput>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Digite aqui o que deseja encontrar:</label>
              <TextField
                size="small"
                value={bodyFilter?.content}
                // inputProps={{ maxLength: 11 }}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, content: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.MediumInput>
          <C.ButtonFilter>
            <Button
              text="Filtrar"
              funcao={GetResults}
              disabled={isDisabled || isLoading}
            />
          </C.ButtonFilter>
        </C.Filters>
      </C.Header>
      <>
        <C.Content>
          {isLoading ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              {results.map((result, index) => {
                return (
                  <>
                    <C.TitleAndExport>
                      <C.InformationsPatient>
                        <C.ContainerInformation>
                          <C.AlignRow>
                            <div>
                              <C.BoldText>Atendimento:</C.BoldText>
                              <C.NormalText>{result?.atendimento}</C.NormalText>
                            </div>
                            -
                            <div>
                              <C.BoldText>Data:</C.BoldText>
                              <C.NormalText>{result?.date_block}</C.NormalText>
                            </div>
                          </C.AlignRow>
                        </C.ContainerInformation>
                        <C.ContainerInformation>
                          <C.BoldText>Paciente:</C.BoldText>
                          <C.NormalText>
                            {result?.client_name} -{" "}
                            {`${result?.client_nascimento}`}
                          </C.NormalText>
                        </C.ContainerInformation>
                      </C.InformationsPatient>
                      <C.Export
                        onClick={() => printAll(result, index)}
                        disabled={progressPdfAll}
                      >
                        <Printer
                          color="#041e42"
                          style={{ marginLeft: "10px" }}
                        />
                        {progressPdfAll && index === IdRow ? (
                          <p>Carregando...</p>
                        ) : (
                          <p>Exames Laboratório</p>
                        )}
                      </C.Export>
                    </C.TitleAndExport>

                    <C.DividerTable>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ backgroundColor: "#F3F2F7" }}
                            >
                              <TableCell
                                align="left"
                                style={{ fontWeight: 600 }}
                              >
                                Exame
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 600, width: "20%" }}
                              >
                                Imprimir
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {result?.registers?.map((row, index) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    style={{ fontWeight: 500 }}
                                  >
                                    {row?.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontWeight: 500, width: "20%" }}
                                  >
                                    {row?.type === "pdf" && row?.result_url ? (
                                      progressPdfUnit && index === IdRow ? (
                                        <C.TextLoad>Carregando...</C.TextLoad>
                                      ) : (
                                        <Printer
                                          color="#041E42"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            redirectByServiceId(row, index);
                                          }}
                                        />
                                      )
                                    ) : row?.type === "imagem" &&
                                      row?.result_url ? (
                                      loadingLink && index === IdRow ? (
                                        <C.TextLoad>Carregando...</C.TextLoad>
                                      ) : (
                                        <Printer
                                          color="#041E42"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => redirect(row, index)}
                                        />
                                      )
                                    ) : (
                                      <C.Icon>não disponível</C.Icon>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </C.DividerTable>
                  </>
                );
              })}
            </>
          )}
        </C.Content>
      </>
    </C.Container>
  );
}
