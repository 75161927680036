import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 100px;
`;

export const ContainerIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const ContentForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px;
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;

export const ContainerButton = styled.div`
  margin-top: 30px;
`;
export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
`;
