import * as C from "./styles";
import api from "../../../../services/api.js";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../../components/Button";
import { StateGlobal } from "../../../../context/GlobalContext";
import background_logo from "../../../../assets/images/logo-centro-medico.png";
import OtpInput from "react-otp-input"; //https://www.npmjs.com/package/react-otp-input
import { setIsDoctor, saveToken } from "../../../../services/auth";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export default function DoctorToken() {
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const { data_doctor, setData_doctor } = StateGlobal();
  const [token, setToken] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [auxTimer, setAuxTimer] = useState(false);
  const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(2 * 60);
  const minutes = Math.floor(totalTimeInSeconds / 60);
  const seconds = totalTimeInSeconds % 60;

  useEffect(() => {
    if (totalTimeInSeconds === 0) {
      setAuxTimer(false);
      setTotalTimeInSeconds(2 * 60);
      return;
    } else if (auxTimer === true) {
      setTimeout(() => {
        setTotalTimeInSeconds(totalTimeInSeconds - 1);
      }, 1000);
    }
  }, [totalTimeInSeconds, auxTimer]);

  function handleNext() {
    navigate("/register/3");
  }

  function handleBack() {
    navigate("/doutor/login");
  }

  async function ResendToken() {
    setAuxTimer(true);
    requestToken();
  }

  function verifyDisabled() {
    if (token?.length === 6 || token?.length === "6") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  async function requestToken() {
    try {
      if (data_doctor?.email !== "") {
        const res = await api.post("api/doctor/token/send", {
          email: data_doctor?.email,
          password: data_doctor.password
        });
        if (res.data.error === "false") {
          setAuxTimer(true);
        }
        MySwal.fire({
          icon: res.data.error === "false" ? "success" : "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      // else {
      //   const res = await api.post("/api/client/token/send", {
      //     type: "phone",
      //     content: data_user?.phone,
      //   });
      //   MySwal.fire({
      //     icon: res.data.error === "false" ? "success" : "error",
      //     title: res.data.message,
      //     showConfirmButton: false,
      //     timer: 3000,
      //   });
      // }
    } catch (err) {
      console.log(err);
    }
  }

  async function SendToken() {
    try {
      const res = await api.post("/api/doctor/token/validate", {
        token,
      });
      if (res.data.error === "true") {
        MySwal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      if (res.data.error === "false") {
        setData_doctor({
          email: res.data.user?.email,
          id: res.data.user?.id,
          name: res.data.user?.name,
          token: res.data.user?.token,
        });
        saveToken(res.data.user?.token);
        setIsDoctor(true);
        setTimeout(() => {
          window.location.href = `/home`;
        }, [1000]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    verifyDisabled();
  }, [token]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Text>
            Enviamos em seu e-mail um código de validação de 6 números, por
            favor digite abaixo:
          </C.Text>
          <C.ContainerOTPInput>
            <OtpInput
              value={token}
              numInputs={6}
              separator={" "}
              inputStyle={{
                width: "2.5625rem",
                marginTop: "1rem",
                marginLeft: "0.25rem",
                marginRight: "0.25rem",
                height: "2.375rem",
                fontSize: "2rem",
                borderRadius: 4,
                border: "1px solid rgba(0,0,0,0.3)",
              }}
              onChange={(e) => setToken(e)}
            />
          </C.ContainerOTPInput>
          {auxTimer === false ? (
            <C.UnderlineText onClick={ResendToken}>
              Reenviar Código
            </C.UnderlineText>
          ) : (
            <C.TextDisabled>
              Reenviar Código -{" "}
              <b>
                {" "}
                {minutes}:{seconds.toString().padStart(2, "0")}{" "}
              </b>
            </C.TextDisabled>
          )}
        </C.Form>
        <C.ContainerButton>
          <PrimaryButton
            text="Continuar"
            funcao={SendToken}
            disabled={isDisabled}
          />
          <C.TextBack onClick={handleBack}>Voltar</C.TextBack>
        </C.ContainerButton>
      </C.Content>
    </C.Container>
  );
}
