import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white_secondary};
  width: 100%;
  padding: 0px 30px;
`;

export const Logo = styled.div`
width: 100%;
display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-left: 10px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 25px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: -10px;
`;

export const DateTime = styled.span`
  font-weight: 500;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey};
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
    margin-bottom: 0px;
  }
`;

export const TextLoad = styled.p`
  color: #0f2554;
  font-size: 14px;
  font-weight: 500;
`;

export const SmallInput = styled.div`
  max-width: 170px;
  height: 40px;
  margin-right: 20px;
`;

export const MediumInput = styled.div`
  width: 300px;
  height: 40px;
`;

export const Export = styled.button`
  border: 2px solid #041e42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: fit-content;
  cursor: pointer;

  p {
    margin: 0px 0px;
    padding: 5px 5px;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const TitleAndExport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`

export const Icon = styled.span`
  max-width: 108px;
  height: 20px;
  padding: 2px 9px;
  background-color: rgba(51, 51, 51, 0.12);
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
`;

export const Content = styled.div`
  margin-top: 30px;
`;

export const ButtonFilter = styled.div`
  margin-left: 0px;
  min-width: 170px;
  margin-top: 18px;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
`;

export const DivLoading2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  width: 100%;
`;

export const DivPagination = styled.div`
  margin: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DividerTable = styled.div`
  width: 100%;
  margin: 10px 0px;
`