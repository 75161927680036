import * as C from "./styles";
import api from "../../../services/api.js";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/Button";
import { StateGlobal } from "../../../context/GlobalContext";
import { CpfMask, PhoneMask } from "../../../components/Masks";
import background_logo from "../../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
export default function RegisterStepOne() {
  const MySwal = withReactContent(Swal);

  const { data_user, setData_user } = StateGlobal();
  const [disabled, setDisabled] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [resMessage, setResMessage] = useState(false);
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  function handleLogin() {
    navigate("/login");
  }

  async function sendToken() {
    setDisabled(true);
    try {
      const res = await api.post("/api/client/token/verify", {
        cpf: data_user?.cpf,
      });
      if (res?.data.error === "false") {
        navigate("/register/verification", {
          state: {
            email_label: res.data.email_label,
            phone_label: res.data.phone_label,
          },
        });
        setData_user({
          ...data_user,
          email: res.data.email,
          phone: res.data.phone,
        });
        setShowMessage(false);
        setResMessage(false);
      } else if (res?.data?.error_code === 1) {
        setResMessage(false);
        setShowMessage(true);
      } else {
        setShowMessage(false);
        setMessage(res?.data?.message);
        setResMessage(true);
      }
    } catch (err) {
      setShowMessage(true);
    } finally {
      setDisabled(false);
    }
  }

  useEffect(() => {
    if (data_user.cpf === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data_user]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Bem vindo! 👋🏻</C.Title>
          <C.Text>
            Para iniciar seu cadastro digite seu telefone ou e-mail de cadastro:
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              placeholder="000.000.000-00"
              size="small"
              value={data_user?.cpf ? CpfMask(data_user?.cpf) : data_user?.cpf}
              inputProps={{ maxLength: 12 }}
              onChange={(e) =>
                setData_user({ ...data_user, cpf: e.target.value })
              }
            />
          </C.InputAndLabel>
          {showMessage && (
            <C.Text style={{ color: "red", textAlign: "center" }}>
              Ops... não encontramos seus dados em nosso banco de dados, por
              favor entre em contato em nosso{" "}
              <C.Link
                href="https://centromedicosorocaba.com.br/contato/"
                target="_blank"
              >
                canais de atendimento.
              </C.Link>
            </C.Text>
          )}
          {resMessage && (
            <C.Text style={{ color: "red", textAlign: "center" }}>
              {message}
            </C.Text>
          )}
          <C.ContainerButton>
            <PrimaryButton
              text="Continuar"
              funcao={sendToken}
              disabled={disabled}
            />
          </C.ContainerButton>
          <C.Text style={{ textAlign: "center" }}>
            Já tem cadastro? <b onClick={handleLogin}>Faça login agora</b>
          </C.Text>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
