import * as C from "./styles";
import { useState, useEffect } from "react";
import api from "../../services/api";
import { Printer, ArrowUpRight } from "react-feather";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Button from "../../components/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import { TextField, CircularProgress } from "@mui/material";
import logo from "../../assets/images/logo-centro-medico.png";
import { getIsDoctor } from "../../services/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function PrivateResults() {
  const isDoctor = getIsDoctor();
  const MySwal = withReactContent(Swal);
  const [progressPdf, setProgressPdf] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [IdRow, setIdRow] = useState("");
  const [progressPdfUnit, setprogressPdfUnit] = useState(false);
  const [progressPdfAll, setprogressPdfAll] = useState(false);

  async function GetResults() {
    try {
      setIsLoading(true);
      const res = await api.get(`/api/anonymous/list/results`);

      setResults(res.data);
      setIsLoading(false);
      // if (res.data.error === "true") {
      //   MySwal.fire({
      //     icon: "error",
      //     title: res.data.message,
      //     showConfirmButton: false,
      //     timer: 3000,
      //   });
      // setResults([]);
      // setIsLoading(false);
      // } else if (res.data !== [] || res.data !== "" || res.data !== "") {
      //   setResults(res.data);
      //   setIsLoading(false);
      // } else {
      //   setResults([]);
      // }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    GetResults();
  }, []);

  function redirect(result_url) {
    window.open(`${result_url}`, "_blank");
  }

  async function redirectByServiceId(row, index) {
    setIdRow(index);
    setprogressPdfUnit(true);

    const { data } = await api.post(
      `/api/anonymous/list/results/single/print`,
      {
        complemento_atendimento: row.complemento_atendimento,
      }
    );

    const linkSource = `data:application/pdf;base64,${data.pdf_data}`;
    const downloadLink = document.createElement("a");
    const fileName = `${row.name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    setprogressPdfUnit(false);
  }

  async function printAll(result, index) {
    setIdRow(index);
    setprogressPdfAll(true);
    try {
      const {data} = await api.post("/api/anonymous/list/results/print", {
        date: result?.date_block,
        atendimento: result?.atendimento,
      });
      if (data.pdf_data) {
        const linkSource = `data:application/pdf;base64,${data.pdf_data}`;
        const downloadLink = document.createElement("a");
        const fileName = `Atendimentos.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        setprogressPdfAll(false);
      } else {
        MySwal.fire({
          icon: "error",
          title: data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        setprogressPdfAll(false);
      }
    } catch (err) {
      console.log(err);
      setProgressPdf(false);
    }
  }

  return (
    <C.Container>
      <C.Logo>
        <img
          alt="logo"
          src={logo}
          width={160}
          height={50}
        />
      </C.Logo>

      <C.Title>Meus Resultados</C.Title>

      <C.Header></C.Header>
      <>
        <C.Content>
          {isLoading === true ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              {results?.map((result, index) => {
                return (
                  <>
                    <C.TitleAndExport>
                      <C.DateTime>
                        {result?.date_block} -{" "}
                        {`Atendimento: ${result?.atendimento}`}
                      </C.DateTime>

                      <C.Export
                        onClick={() => printAll(result, index)}
                        disabled={progressPdfAll}
                      >
                        <Printer
                          color="#041e42"
                          style={{ marginLeft: "10px" }}
                        />
                        {progressPdfAll && index === IdRow ? (
                          <p>Carregando...</p>
                        ) : (
                          <p>Exames Laboratório</p>
                        )}
                      </C.Export>
                    </C.TitleAndExport>

                    <C.DividerTable>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                        >
                          <TableHead>
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ backgroundColor: "#F3F2F7" }}
                            >
                              <TableCell
                                align="left"
                                style={{ fontWeight: 600 }}
                              >
                                Exame
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 600, width: "20%" }}
                              >
                                Imprimir
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {result?.registers?.map((row,index) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    align="left"
                                    style={{ fontWeight: 500 }}
                                  >
                                    {row?.name}
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ fontWeight: 500, width: "20%" }}
                                  >
                                    {row?.type === "pdf" && row?.result_url ? (
                                      progressPdfUnit && index === IdRow ? (
                                        <C.TextLoad>Carregando...</C.TextLoad>
                                      ) : (
                                        <Printer
                                          color="#041E42"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            redirectByServiceId(row, index);
                                          }}
                                        />
                                      )
                                    ) : row?.type === "imagem" &&
                                      row?.result_url ? (
                                      <Printer
                                        color="#041E42"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          redirect(row?.result_url)
                                        }
                                      />
                                    ) : (
                                      <C.Icon>não disponível</C.Icon>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </C.DividerTable>
                  </>
                );
              })}
            </>
          )}
        </C.Content>
      </>
    </C.Container>
  );
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
