import React, { createContext, useState, useContext } from "react";

export const GlobalContext = createContext({});

export default function CountProvider({ children }) {
  const [components, setComponents] = useState(1);
  const [dataInsurance, setDataInsurance] = useState({
    id: "",
    name: "",
    email: "",
    token: "",
  });
  const [data_doctor, setData_doctor] = useState({
    id: "",
    cm_id: "",
    name: "",
    cpf: "",
    birthday: "",
    email: "",
    phone: "",
    token: "",
    password: "",
  });
  const [data_user, setData_user] = useState({
    name: "",
    cpf: "",
    birthday: "",
    email: "",
    phone: "",
    password: "",
    photo: null,
  });

  const [clientData, setClientData] = useState({
    photo: null,
  });

  return (
    <GlobalContext.Provider
      value={{
        components,
        setComponents,
        data_user,  
        setData_user,
        data_doctor,
        setData_doctor,
        clientData,
        setClientData,
        dataInsurance,
        setDataInsurance,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function StateGlobal() {
  const context = useContext(GlobalContext);
  const { components, setComponents } = context;
  const { data_user, setData_user } = context;
  const { data_doctor, setData_doctor } = context;
  const { clientData, setClientData } = context;
  const { dataInsurance, setDataInsurance } = context;
  return {
    components,
    setComponents,
    data_user,
    setData_user,
    data_doctor,
    setData_doctor,
    clientData,
    setClientData,
    dataInsurance,
    setDataInsurance,
  };
}
