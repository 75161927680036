import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white_secondary};
  width: 100%;
  padding: 0px 30px;
`;

export const Logo = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 25px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 4%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
  }
`;

export const SmallInput = styled.div`
  max-width: 170px;
  height: 40px;
  margin-right: 20px;
`;

export const Content = styled.div`
  margin-top: 32px;
`;

export const ButtonFilter = styled.div`
  min-width: 170px;
  margin-top: 18px;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
`;

export const DivPagination = styled.div`
  margin: 10px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
