import { GlobalStyle } from "./assets/global";
import { ThemeProvider } from "styled-components";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateLogin from "./pages/PrivateLogin";
import ForgotPassword from "./pages/ForgotPassword";
import theme from "./assets/themes/theme";
import LeftMenu from "./components/LeftMenu";
import GlobalContext from "./context/GlobalContext";
import RegisterStepOne from "./pages/Register/Step1";
import RegisterStepTwo from "./pages/Register/Step2";
import RegisterStepThree from "./pages/Register/Step3";
import RegisterStepFour from "./pages/Register/Step4";
import DoctorLogin from "./pages/Doctor/Login/StepOne";
import DoctorToken from "./pages/Doctor/Login/StepTwo";
import PrivateHome from "./pages/PrivateHome";
import InitialScreen from "./pages/InitialScreen";
import LoginInsurance from "./pages/Insurance/LoginInsurance";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StepVerification from "./pages/Register/StepVerification";
import StepCode from "./pages/Insurance/LoginInsurance/StepCode";
import HomeInsurance from "./pages/Insurance/HomeInsurance";

function App() {
  return (
    <>
      <GlobalContext>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Routes>
              <Route
                exact
                path="/"
                element={<InitialScreen />}
              />
              <Route
                exact
                path="/login"
                element={<Login />}
              />
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/doutor/login"
                element={<DoctorLogin />}
              />
              <Route
                exact
                path="/doutor/token"
                element={<DoctorToken />}
              />
              <Route
                exact
                path="/left"
                element={<LeftMenu />}
              />
              <Route
                exact
                path="/home"
                element={<Home />}
              />
              <Route
                exact
                path="/private"
                element={<PrivateHome />}
              />
              <Route
                exact
                path="/register/1"
                element={<RegisterStepOne />}
              />
              <Route
                exact
                path="/register/verification"
                element={<StepVerification />}
              />
              <Route
                exact
                path="/register/2"
                element={<RegisterStepTwo />}
              />
              <Route
                exact
                path="/register/3"
                element={<RegisterStepThree />}
              />
              <Route
                exact
                path="/register/4"
                element={<RegisterStepFour />}
              />
              <Route
                exact
                path="/login/private"
                element={<PrivateLogin />}
              />

              {/* Modulo de convenios */}
              <Route
                exact
                path="/login/convenios"
                element={<LoginInsurance />}
              />
              <Route
                exact
                path="/login/convenios/codigo"
                element={<StepCode />}
              />
              <Route
                exact
                path="/home/convenios"
                element={<HomeInsurance />}
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </GlobalContext>
    </>
  );
}

export default App;
