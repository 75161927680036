import * as C from "./styles";
import api from "../../../services/api";
import { Printer } from "react-feather";
import Table from "@mui/material/Table";
import Button from "../../../components/Button";
import Paper from "@mui/material/Paper";
import { TextField, CircularProgress } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Pagination from "@mui/material/Pagination";
import React, { useState, useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import logo from "../../../assets/images/logo-centro-medico.png";
import { getIsDoctor } from "../../../services/auth";

export default function ProcedimentsInsurance() {
  const isDoctor = getIsDoctor();
  const [lastPage, setLastPage] = useState(1);
  const [savePage, setSavePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [progressPdf, setProgressPdf] = useState(false);
  const [bodyFilter, setBodyFilter] = useState({
    content: "",
  });
  const [procediments, setProcediments] = useState([]);
  const [IdRow, setIdRow] = useState(``);

  async function getProcediment() {
    setIsLoading(true);
    try {
      const res = await api.post(
        `/api/insurance/list/procedures?page=${savePage}`,
        bodyFilter
      );

      setLastPage(res.data.last_page);
      setProcediments(res.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function getUrl(row) {
    const res = await api.get(
      `/api/insurance/list/procedure/pdf/${row.type}/${row.id}`
    );
    setProgressPdf(false);
    window.open(`${res.data.url}`, "_blank");
  }

  useEffect(() => {
    getProcediment();
  }, [savePage]);

  return (
    <C.Container>
      <C.Logo>
        <img alt="logo" src={logo} width={160} height={50} />
      </C.Logo>
      <C.Title>Nossos Procedimentos</C.Title>
      <C.Content>
        <C.Filters>
          <C.MediumInput>
            <C.InputAndLabel>
              <TextField
                size="small"
                placeholder="Pesquisar"
                style={{ backgroundColor: "#fff" }}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, content: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.MediumInput>
          <C.ButtonFilter>
            <Button
              text="Filtrar"
              funcao={getProcediment}
              disabled={isLoading}
            />
          </C.ButtonFilter>
        </C.Filters>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            {isLoading ? (
              <C.DivLoading>
                <CircularProgress
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "#0F2554",
                  }}
                />
              </C.DivLoading>
            ) : (
              <>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                    <TableCell align="center">Procedimento</TableCell>
                    <TableCell align="center">Imprimir</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {procediments?.map((row, index) => {
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={row.id}
                      >
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="center">
                          {progressPdf && row.id === IdRow ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "#041E42" }}
                            />
                          ) : (
                            <Printer
                              color="#041E42"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                getUrl(row);
                                setProgressPdf(true);
                                setIdRow(row.id);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            )}
          </Table>
          <C.DivPagination>
            <Pagination
              page={Number(savePage)}
              disabled={isLoading}
              color="primary"
              count={lastPage}
              onChange={(e) => {
                setSavePage(e.target.innerText);
              }}
            />
          </C.DivPagination>
        </TableContainer>
      </C.Content>
    </C.Container>
  );
}
