import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : "",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.response.use(function (response) {
  return response;
});

export default api;
