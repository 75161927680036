import * as C from "./styles";
import api from "../../services/api";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PrimaryButton from "../../components/Button";
import { StateGlobal } from "../../context/GlobalContext";
import { saveToken, setIsDoctor } from "../../services/auth";
import background_logo from "../../assets/images/logo-centro-medico.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useEffect } from "react";
import { CpfMask } from "../../components/Masks";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [cpf, setCpf] = useState("");
  const { setComponents } = StateGlobal();
  const [disabled, setDisabled] = useState(true);

  function handleRegister() {
    navigate("/login");
  }

  async function handleSubmit() {
    setDisabled(true);
    try {
      const res = await api.post("/api/client/password_reset", {
        cpf: cpf?.replace(/[^0-9s]/g, ""),
      });
      if (res.data.error === "false") {
        setIsDoctor(false);
        saveToken(res.data.token);
        setComponents(1);
      }
      MySwal.fire({
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 3000,
      });
      navigate("/login");
    } catch (err) {
      if (err.response.status === 422) {
        MySwal.fire({
          icon: "error",
          title: "CPF inválido",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Erro! tente novamente",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
    setDisabled(false);
  }

  function validateMail() {
    if (cpf !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  useEffect(() => {
    validateMail();
  }, [cpf]);

  return (
    <C.Container>
      <C.Header />
      <C.Content>
        <C.Image>
          <img alt="logo centro medico" src={background_logo} />
        </C.Image>
        <C.Form>
          <C.Title>Esqueceu sua senha?</C.Title>
          <C.Text>
            Por favor preencha seu CPF de cadastro para enviarmos uma senha
            temporária.
          </C.Text>
          <C.InputAndLabel>
            <label id="cpf">CPF</label>
            <TextField
              type="cpf"
              size="small"
              placeholder="Digite seu CPF de cadastro"
              value={CpfMask(cpf) ?? cpf}
              onChange={(e) => setCpf(e.target.value)}
              inputProps={{ maxLength: 14 }}
            />
          </C.InputAndLabel>
          {/* <C.InputAndLabel>
            <div>
              <label>Senha</label>
              <p>Esqueceu a senha?</p>
            </div>
            <TextField
              size="small"
              type="password"
              placeholder="⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉ ⚉"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </C.InputAndLabel> */}
          <C.ContainerButton>
            <PrimaryButton
              text="Enviar nova senha"
              funcao={handleSubmit}
              disabled={disabled}
            />
          </C.ContainerButton>
          <C.BoxBack>
            <C.Text>
              <b onClick={handleRegister}>Voltar</b>
            </C.Text>
          </C.BoxBack>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
