import * as C from "./styles";
import api from "../../services/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken, getIsDoctor } from "../../services/auth";
import { StateGlobal } from "../../context/GlobalContext";
import logoCentroMedico from "../../assets/images/logoCentroMedico.png";
import { CircularProgress } from "@mui/material";

import { Calendar, Mail, Clipboard, User, ArrowLeft } from "react-feather";
export default function LeftMenu() {
  const [isLoading, setIsLoading] = useState(false);
  const isDoctor = getIsDoctor();
  const [user, setUser] = useState({
    id: 1,
    cm_id: "",
    name: "",
    cpf: "",
    birthday: "",
    email: "",
    phone: "",
  });
  const { setComponents, clientData, setClientData } = StateGlobal();
  const navigate = useNavigate();
  const data =
    isDoctor === "false"
      ? [
        {
          id: 1,
          icon: (
            <Clipboard color="#FFF" width={35} height={35} strokeWidth={1} />
          ),
          name: "Meus Resultados",
        },
        {
          id: 2,
          icon: (
            <Calendar color="#FFF" width={35} height={35} strokeWidth={1} />
          ),
          name: "Agendamentos",
        },
        {
          id: 3,
          icon: (
            <Clipboard color="#FFF" width={35} height={35} strokeWidth={1} />
          ),
          name: "Preparos de Exames",
        },
        {
          id: 4,
          icon: <Mail color="#FFF" width={35} height={35} strokeWidth={1} />,
          name: "Fale Conosco",
        },
        {
          id: 5,
          icon: <User color="#FFF" width={35} height={35} strokeWidth={1} />,
          name: "Meu Perfil",
        },
        {
          id: 6,
          icon: (
            <ArrowLeft color="#FFF" width={35} height={35} strokeWidth={1} />
          ),
          name: "Sair",
        },
      ]
      : [
        {
          id: 1,
          icon: (
            <Clipboard color="#FFF" width={30} height={30} strokeWidth={1} />
          ),
          name: "Resultados",
        },

        {
          id: 2,
          icon: (
            <Clipboard color="#FFF" width={30} height={30} strokeWidth={1} />
          ),
          name: "Preparos de Exames",
        },
        {
          id: 4,
          icon: <Mail color="#FFF" width={30} height={30} strokeWidth={1} />,
          name: "Fale Conosco",
        },

        {
          id: 6,
          icon: (
            <ArrowLeft color="#FFF" width={30} height={30} strokeWidth={1} />
          ),
          name: "Sair",
        },
      ];

  function handleResults() {
    setComponents(1);
  }

  function handleSchedules() {
    setComponents(2);
  }

  function handleProcediments() {
    setComponents(3);
  }

  function handleTalkUs() {
    window.open(`https://centromedicosorocaba.com.br/contato/`, "_blank");
  }

  function handleProfile() {
    setComponents(5);
  }

  async function getProfileInfo() {
    setIsLoading(true);
    try {
      if (isDoctor === "false") {
        const res = await api.get("/api/client");
        setUser({
          birthday: res.data.birthday,
          cm_id: res.data.cm_id,
          cpf: res.data.cpf,
          email: res.data.email,
          id: res.data.id,
          name: res.data.name,
          phone: res.data.phone,
        });
        setClientData({
          photo: res.data.photo,
        });
        setIsLoading(false);
      } else {
        const res = await api.get("/api/doctor");
        setUser({
          birthday: res.data.birthday,
          cm_id: res.data.cm_id,
          cpf: res.data.cpf,
          email: res.data.email,
          id: res.data.id,
          name: res.data.name,
          phone: res.data.phone,
        });
        setClientData({
          photo: res.data.photo,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLogout() {
    if (isDoctor === "false") {
      const res = await api.post("/api/client/logout");
      if (res.data.error === "false") {
        removeToken();
        navigate("/login");
      }
    } else {
      const res = await api.post("/api/doctor/logout");
      if (res.data.error === "false") {
        removeToken();
        navigate("/doutor/login");
      }
    }
  }

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <C.LeftMenu>
      {isLoading === true ? (
        <C.DivLoading>
          <CircularProgress
            style={{
              width: "35px",
              height: "35px",
              color: "#FFF",
            }}
          />
        </C.DivLoading>
      ) : (
        <>
          <C.Header>
            <img
              alt="profile"
              src={logoCentroMedico}
            />
            <>
              <C.TitleHeader>
                {isLoading ? "carregando..." : user.name}
              </C.TitleHeader>
              <C.SubtitleHeader>
                {isLoading ? "carregando..." : " Meu ID"}
              </C.SubtitleHeader>
              <C.Text>{isLoading ? "carregando..." : user.cm_id}</C.Text>
            </>
          </C.Header>
          <C.Content>
            <C.MenuOptions>
              {data.map((item, index) => (
                <C.DivMap
                  key={String(index)}
                  onClick={() => {
                    item.name === "Meus Resultados" && handleResults();
                    item.name === "Resultados" && handleResults();
                    item.name === "Agendamentos" && handleSchedules();
                    item.name === "Preparos de Exames" && handleProcediments();
                    item.name === "Fale Conosco" && handleTalkUs();
                    item.name === "Meu Perfil" && handleProfile();
                    item.name === "Sair" && handleLogout();
                  }}
                >
                  {item.icon}
                  <span> {item.name} </span>
                </C.DivMap>
              ))}
            </C.MenuOptions>
          </C.Content>
        </>
      )}
    </C.LeftMenu>
  );
}
