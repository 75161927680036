import * as C from "./styles";
import api from "../../services/api";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { removeToken, getIsDoctor } from "../../services/auth";
import { StateGlobal } from "../../context/GlobalContext";
import logoCentroMedico from "../../assets/images/logoCentroMedico.png";
import { CircularProgress } from "@mui/material";

import { Mail, Clipboard, ArrowLeft } from "react-feather";
export default function LeftMenuPrivate() {
  const [isLoading, setIsLoading] = useState(false);
  const isDoctor = getIsDoctor();
  const [user, setUser] = useState({
    id: 1,
    cm_id: "",
    name: "",
    cpf: "",
    birthday: "",
    email: "",
    phone: "",
  });
  const { setComponents, clientData, setClientData } = StateGlobal();
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      icon: (
        <Clipboard
          color="#FFF"
          width={35}
          height={35}
          strokeWidth={1}
        />
      ),
      name: "Meus Resultados",
    },

    {
      id: 3,
      icon: (
        <Clipboard
          color="#FFF"
          width={35}
          height={35}
          strokeWidth={1}
        />
      ),
      name: "Preparos de Exames",
    },
    {
      id: 4,
      icon: (
        <Mail
          color="#FFF"
          width={35}
          height={35}
          strokeWidth={1}
        />
      ),
      name: "Fale Conosco",
    },
    {
      id: 6,
      icon: (
        <ArrowLeft
          color="#FFF"
          width={35}
          height={35}
          strokeWidth={1}
        />
      ),
      name: "Sair",
    },
  ];

  function handleResults() {
    setComponents(1);
  }


  function handleProcediments() {
    setComponents(3);
  }

  function handleTalkUs() {
    window.open(`https://centromedicosorocaba.com.br/contato/`, "_blank");
  }


  async function getProfileInfo() {
    setIsLoading(true);
    try {
      const res = await api.get("/api/anonymous");
      setUser({
        cm_id: res.data.cm_id,
        name: res.data.name,
      });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLogout() {
    const res = await api.post("/api/anonymous/logout");
    if (res.data.error === "false") {
      removeToken();
      navigate("/login/private");
    }
  }

  useEffect(() => {
    getProfileInfo();
  }, []);

  return (
    <C.LeftMenu>
      {isLoading === true ? (
        <C.DivLoading>
          <CircularProgress
            style={{
              width: "35px",
              height: "35px",
              color: "#FFF",
            }}
          />
        </C.DivLoading>
      ) : (
        <>
          <C.Header>
            <img
              alt="profile"
              src={logoCentroMedico}
            />
            <>
              <C.TitleHeader>
                {isLoading ? "carregando..." : user.name}
              </C.TitleHeader>
              <C.SubtitleHeader>
                {isLoading ? "carregando..." : " Meu ID"}
              </C.SubtitleHeader>
              <C.Text>{isLoading ? "carregando..." : user.cm_id}</C.Text>
            </>
          </C.Header>
          <C.Content>
            <C.MenuOptions>
              {data.map((item, index) => (
                <C.DivMap
                  key={String(index)}
                  onClick={() => {
                    item.name === "Meus Resultados" && handleResults();
                    item.name === "Preparos de Exames" && handleProcediments();
                    item.name === "Fale Conosco" && handleTalkUs();
                    item.name === "Sair" && handleLogout();
                  }}
                >
                  {item.icon}
                  <span> {item.name} </span>
                </C.DivMap>
              ))}
            </C.MenuOptions>
          </C.Content>
        </>
      )}
    </C.LeftMenu>
  );
}
