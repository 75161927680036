import styled from "styled-components";
import background_login from "../../../assets/images/background-login-client.png";

export const Container = styled.div`
  height: 100vh;
  background-image: url(${background_login});
  background-size: cover;
`;

export const Header = styled.div`
  height: 265px;
`;

export const Content = styled.div`
  width: 420px;
  height: auto;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 2;
  border-radius: 10px;
  margin-left: 11%;
  margin-top: 10px;
`;

export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  img {
    width: 221px;
    height: auto;
  }
`;

export const Form = styled.div`
  padding: 0px 0px;
`;

export const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.title_login};
  margin-bottom: 0px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};

  b {
    font-size: 14px;
    font-weight: 900;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Text = styled.p`
  width: 95%;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text_login};
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0px;
    margin-top: 20px;
  }

  label {
    margin-top: 15px;
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
  }

  #password {
    margin-top: 0px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
  margin-bottom: 5%;
`;

export const TextInformation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 0px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
  }
`;

export const TextBack = styled.p`
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.4px;
  cursor: pointer;
`;

export const Alert = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: ${({ theme }) => theme.colors.red};
`;
