import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white_secondary};
  width: 100%;
  padding: 0px 30px;
`;

export const Logo = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 25px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 4%;
  margin-bottom: 0px;
  margin-left: 0px;
`;

export const TextHeader = styled.p`
  margin: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const ItemIconEdit = styled.div`
  width: 35px;
  height: 35px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 85px;
  position: fixed;
  margin-top: -35px;
  cursor: pointer;
`;

export const TextBoldHeader = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Content = styled.div``;

export const TitleContent = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  margin: 4% 0px 5px 5px;
`;

export const Underline = styled.div`
  border: 2px solid #0f2554;
  width: 155px;
  margin-bottom: 25px;
`;

export const Alert = styled.div`
  width: 100%;
  height: auto;
  background-color: #fd5454;
  padding: 15px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
  }
`;

export const Avatar = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;

  img {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;
export const MediumInput = styled.div`
  width: 47%;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Export = styled.div`
  border: 2px solid #041e42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  width: 250px;

  span {
    margin: 0px 0px;
    padding: 3% 3%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Buttons = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 8%;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
`;
