import * as C from "./styles";
import { StateGlobal } from "../../../context/GlobalContext";
import { useEffect } from "react";
import LeftMenuInsurance from "../LeftMenuInsurance";
import ResultsInsurance from "../ResultsInsurance";
import ProcedimentsInsurance from "../ProcedimentsInsurance";

export default function HomeInsurance() {
  const { components, setComponents } = StateGlobal();
  let attPhoto = localStorage.getItem("attPhoto");
  function validateComponents() {
    if (attPhoto) {
      localStorage.removeItem("attPhoto");
      setComponents(5);
    }
  }

  useEffect(() => {
    validateComponents();
  }, []);

  return (
    <C.Container>
      <LeftMenuInsurance />
      {components === 1 ? (
        <ResultsInsurance />
      ) : components === 3 ? (
        <ProcedimentsInsurance />
      ) : (
        ""
      )}
    </C.Container>
  );
}
