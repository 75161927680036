import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const RightContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.red};
  width: 100%;
`;
