import * as C from "./styles";
import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { TextField, CircularProgress } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Pagination from "@mui/material/Pagination";

import TableContainer from "@mui/material/TableContainer";
import logo from "../../assets/images/logo-centro-medico.png";
import Button from "../../components/Button";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
export default function Schedules() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [savePage, setSavePage] = useState(1);
  const [bodyFilter, setBodyFilter] = useState({
    start: "",
    end: "",
  });
  const [schedules, setSchedules] = useState([]);

  async function getSchedules() {
    try {
      setIsLoading(true);
      const res = await api.post(
        `/api/client/list/schedules?page=${savePage}`,
        bodyFilter
      );
      if (res.data.error === "true") {
        MySwal.fire({
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        setSchedules([]);
        setIsLoading(false);
      } else {
        setLastPage(res.data.last_page);
        setSchedules(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getSchedules();
  }, [savePage]);

  return (
    <C.Container>
      <C.Logo>
        <img alt="logo" src={logo} width={160} height={50} />
      </C.Logo>
      <C.Title>Agendamentos</C.Title>
      <C.Header>
        <C.Filters>
          <C.SmallInput>
            <C.InputAndLabel>
              <label>Data inicial:</label>
              <TextField
                type="date"
                size="small"
                value={bodyFilter.start}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, start: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.SmallInput>
          <C.SmallInput>
            <C.InputAndLabel>
              <label>Data final:</label>

              <TextField
                type="date"
                size="small"
                value={bodyFilter.end}
                onChange={(e) =>
                  setBodyFilter({ ...bodyFilter, end: e.target.value })
                }
              />
            </C.InputAndLabel>
          </C.SmallInput>
          <C.ButtonFilter>
            <Button text="Filtrar" funcao={getSchedules} />
          </C.ButtonFilter>
        </C.Filters>
      </C.Header>
      <C.Content>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            {isLoading === true ? (
              <C.DivLoading>
                <CircularProgress
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "#0F2554",
                  }}
                />
              </C.DivLoading>
            ) : (
              <>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                    <TableCell align="left">Data</TableCell>
                    <TableCell align="left">Consulta / Exame</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules.map((row) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={row.id}
                    >
                      <TableCell align="left">{`${row.date}  - ${row.hour} `}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
          <C.DivPagination>
            <Pagination
              page={Number(savePage)}
              disabled={isLoading}
              color="primary"
              count={lastPage}
              onChange={(e) => {
                setSavePage(e.target.innerText);
              }}
            />
          </C.DivPagination>
        </TableContainer>
      </C.Content>
    </C.Container>
  );
}
