// eslint-disable-next-line
const theme = {
  colors: {
    primary: "#0F2554",
    secondary: "#08559F",
    button_primary: "#0F2554",
    button_secundary: "#24CC63",
    red: "#ED1616",
    white: "#FFF",
    white_secondary: "#FAFAFA",
    black: "#000",
    grey: "#333",
    title_login: "#5E5873",
    text_login: "#6E6B7B",
  },
};
export default theme;
